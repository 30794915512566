@import "./themes/generated/variables.base.scss";

body {
    font-family:
      -apple-system,
      BlinkMacSystemFont,
      'Segoe UI',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.app {
    background-color: darken($base-bg, 5.00);
    display: flex;
    height: 100%;
    width: 100%;

    .content {
        line-height: 1.5;
        flex-grow: 1;

        h2 {
          font-size: 30px;
          margin-top: 20px;
          margin-bottom: 20px;
        }
    }

    .container {
        height: 100%;
        flex-direction: column;
        display: flex;
    }

    .layout-body {
        flex: 1;
        min-height: 0;
    }

    .content-block {
        margin-left: 40px;
        margin-right: 40px;
        margin-top: 20px;
    }
}

.side-nav-outer-toolbar .dx-drawer {
    height: calc(100% - 56px)
}

.screen-x-small .content-block {
    margin-left: 20px;
    margin-right: 20px;
}

.responsive-paddings {
    padding: 20px;

    .screen-large & {
        padding: 40px;
    }
}

.dx-card.wide-card {
    border-radius: 0;
    margin-left: 0;
    margin-right: 0;
    border-right: 0;
    border-left: 0;
}

.with-footer > .dx-scrollable-wrapper >
.dx-scrollable-container > .dx-scrollable-content {
    height: 100%;

    & > .dx-scrollview-content {
        display: flex;
        flex-direction: column;
        min-height: 100%;
    }
}

$side-panel-min-width: 60px;

html, body {
    margin: 0px;
    min-height: 100%;
    height: 100%;
}

#root {
    height: 100%;
}

* {
    box-sizing: border-box;
}

.dx-tab .dx-tab-text {
    -moz-column-gap: 8px;
    column-gap: 8px;
    line-height: 1.714286;
    font-weight: 500;
    text-transform: initial;
}

.dx-button-text {
    text-transform: initial;
}